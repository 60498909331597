const CONST = {
  baseUrl: "https://api.avex.id/waitlist",
  defaultChest: [
    {
      "_id": "663e62e48f5488a4ec25103c",
      "chestId": "v7mdm8gpm",
      "chestType": {
        "isPaid": true,
        "sequence": 1
      },
      "chestName": "Mythic Chest",
      "description": "The Mythic Insider NFT has several benefits like:",
      "bannerUrl": "https://assets.avex.id/platforms/waitlist/chests/banners/mythic.png",
      "iconUrl": "https://assets.avex.id/platforms/waitlist/chests/mythic.png",
      "additionalImageUrl": "https://assets.avex.id/platforms/waitlist/chests/mythic.png",
      "slotsRemaining": 10,
      "price": 100000,
      "notBeforeAt": "2024-08-26T15:15:00.000Z",
      "faq": {
          "question": "What are the benefits of Mythic Insider NFTs?",
          "answer": "The Mythic Insider NFT has several in-app benefits like : Early Access to app by Augest 25th, 10% Fee discounts on trading fees. Free Premium Features for 1 month Faster level-ups,  50 gasless transactions coupons per month for a year and more Read the blog to know more details"
      },
      "blogLink": "https://medium.com/@maharsi/the-defy-nft-collections-1bf8ab0c755b",
      "subtexts": [
           <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">- Early Access to app by <span className="font-SatoshiBold text-black">Augest 25th</span></p>,
           <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">- <span className="font-SatoshiBold text-black">10%</span> Fee discounts on trading fees.</p>,
           <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2"></p>,
           <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">- Free <span className="font-SatoshiBold text-black">Premium</span> Features for 1 month</p>,
           <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2"></p>,
           <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">- <span className="font-SatoshiBold text-black">50</span> gasless transactions per month</p>,
      ],
      "reward": {
        "points": 0,
        "tickets": 0,
        "avex_handle": false,
        "nonFungibleAssets": [
            {
                "name": "Mythic NFT",
                "value": 1,
                "imageUrl": "https://assets.avex.id/platforms/waitlist/chests/mythic.png"
            }
        ]
    },
      "createdAt": "2024-06-07T10:58:50.508Z"
    },
    {
      "_id": "663e632c8f5488a4ec25103d",
      "chestId": "1kfwbtrgt",
      "chestType": {
        "isPaid": true,
        "sequence": 2
      },
      "chestName": "Legendary Chest",
      "description": "The Legendary Insider NFT has several benefits like:",
      "bannerUrl": "https://assets.avex.id/platforms/waitlist/chests/banners/legendary.png",
      "iconUrl": "https://assets.avex.id/platforms/waitlist/chests/legendary.png",
      "additionalImageUrl": "https://assets.avex.id/platforms/waitlist/chests/legendary.png",
      "slotsRemaining": 10,
      "price": 75000,
      "notBeforeAt": "2024-09-21T12:00:00.000Z",
      "faq": {
          "question": "What are the benefits of Legendary Insider NFTs?",
          "answer": "The Legendary Insider NFT has several in-app benefits like : Early Access to app by Sep 21th, 10% Fee discounts on trading fees. Free Premium Features for 1 month Faster level-ups,  50 gasless transactions coupons per month for a year and more Read the blog to know more details"
      },
      "blogLink": "https://medium.com/@maharsi/the-defy-nft-collections-1bf8ab0c755b",
      "subtexts": [
          <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">-Early Access to app by <span className="font-SatoshiBold text-black">Sep 21th</span></p>,
          <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">-<span className="font-SatoshiBold text-black">8%</span> Fee discounts on trading fees</p>,
          <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">-Free <span className="font-SatoshiBold text-black">Premium</span> Features for 1 month</p>,
          <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">-<span className="font-SatoshiBold text-black">40</span> gasless transactions per month</p>
      ],
      "reward": {
        "points": 0,
        "tickets": 9,
        "avex_handle": false,
        "nonFungibleAssets": [
            {
                "name": "Legendary NFT",
                "value": 1,
                "imageUrl": "https://assets.avex.id/platforms/waitlist/chests/mythic.png"
            }
        ]
    },
      "createdAt": "2024-05-07T11:02:11.467Z"
    },
    {
      "_id": "663e63628f5488a4ec25103e",
      "chestId": "bosfzh1yh",
      "chestType": {
        "isPaid": true,
        "sequence": 3
      },
      "chestName": "Epic Chest",
      "description": "The Epic Insider NFT has several benefits like:",
      "bannerUrl": "https://assets.avex.id/platforms/waitlist/chests/banners/epic.png",
      "iconUrl": "https://assets.avex.id/platforms/waitlist/chests/epic.png",
      "additionalImageUrl": "https://assets.avex.id/platforms/waitlist/chests/epic.png",
      "slotsRemaining": 10,
      "price": 50000,
      "notBeforeAt": "2024-09-25T12:00:00.000Z",
      "faq": {
          "question": "What are the benefits of Mythic Insider NFTs?",
          "answer": "The Mythic Insider NFT has several benefits like: Early Access to app by Sep 25th, 10% Fee discounts on trading fees. Free Premium Features for 1 month Faster level-ups,  50 gasless transactions coupons per month for a year and more Read the blog to know more details"
      },
      "blogLink": "https://medium.com/@maharsi/the-defy-nft-collections-1bf8ab0c755b",
      "subtexts": [
        <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">-Early Access to app by <span className="font-SatoshiBold text-black">sep 25th</span></p>,
        <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">-<span className="font-SatoshiBold text-black">6%</span> Fee discounts on trading fees</p>,
        <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">-Free <span className="font-SatoshiBold text-black">Premium</span> Features for 1 month</p>,
        <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">-<span className="font-SatoshiBold text-black">30</span> gasless transactions per month</p>
    ],
      "reward": {
        "points": 0,
        "tickets": 6,
        "avex_handle": false,
        "nonFungibleAssets": [
            {
                "name": "Epic NFT",
                "value": 1,
                "imageUrl": "https://assets.avex.id/platforms/waitlist/chests/mythic.png"
            }
        ]
    },
      "createdAt": "2024-05-07T11:03:12.245Z"
    },
    {
      "_id": "663e63928f5488a4ec25103f",
      "chestId": "kl2yf2u6k",
      "chestType": {
        "isPaid": true,
        "sequence": 4
      },
      "chestName": "Rare Chest",
      "description": "The Rare NFT has several benefits like: ",
      "bannerUrl": "https://assets.avex.id/platforms/waitlist/chests/banners/rare.png",
      "iconUrl": "https://assets.avex.id/platforms/waitlist/chests/rare.png",
      "additionalImageUrl": "https://assets.avex.id/platforms/waitlist/chests/rare.png",
      "slotsRemaining": 10,
      "price": 25000,
      "notBeforeAt": "2024-09-20T12:00:00.000Z",
      "faq": {
          "question": "What are the benefits of Mythic Insider NFTs?",
          "answer": "The Mythic Insider NFT has several in-app benefits like : Early Access to app by September 20th, 10% Fee discounts on trading fees. Free Premium Features for 1 month Faster level-ups,  50 gasless transactions coupons per month for a year and more Read the blog to know more details"
      },
      "blogLink": "https://medium.com/@maharsi/the-defy-nft-collections-1bf8ab0c755b",
      "subtexts": [
          "Early Access to app by Augest 05th ",
          "5% Fee discounts on trading fees.",
          "Free Premium Features for 1 month",
          "Faster level-ups",
          "20 gasless transactions coupons per month for a year and more"
      ],
      "subtexts": [
        <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">-Early Access to app by <span className="font-SatoshiBold text-black">Sep 20th</span></p>,
        <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">-<span className="font-SatoshiBold text-black">5%</span> Fee discounts on trading fees</p>,
        <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">-Free <span className="font-SatoshiBold text-black">Premium</span> Features for 1 month</p>,
        <p className="font-SatoshiMedium text-sm text-textGreyDark text-left mt-2">-<span className="font-SatoshiBold text-black">20</span> gasless transactions per month</p>
    ],
      "reward": {
        "points": 0,
        "tickets": 3,
        "avex_handle": false,
        "nonFungibleAssets": [
            {
                "name": "Rare NFT",
                "value": 1,
                "imageUrl": "https://assets.avex.id/platforms/waitlist/chests/mythic.png"
            }
        ]
    },
      "createdAt": "2024-05-07T11:03:46.510Z"
    },

  ],
  defaultTask: [
    {
      "_id": "663e58918f5488a4ec25102f",
      "taskId": "9ni84f4p9",
      "title": "Connect your wallet",
      "description": "Claim upto 3000 coins by connecting your wallet. The scores are based on: your activity, wallet age and total assets held",
      "bannerUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/wallet.png",
      "iconUrl": "https://assets.avex.id/platforms/waitlist/socials/wallet.png",
      "additionalImageUrl": "https://assets.avex.id/platforms/waitlist/socials/wallet.png",
      "taskType": "connection",
      "platform": "wallet",
      "tags": [
        "defi"
      ],
      "reward": {
        "points": 3000,
        "tickets": 0
      },
      "isActive": true,
      "boost": {
        "isBoosted": false,
        "multiplier": 1
      },
      "faq": [],
      "expiresAt": "2024-01-01T00:00:00.000Z",
      "notBeforeAt": "2024-05-10T17:25:37.288Z",
      "createdAt": "2024-05-10T17:25:37.288Z"
    },
    {
      "_id": "663e58e58f5488a4ec251030",
      "taskId": "knww9mbz8",
      "title": "Verify your X account",
      "description": "Claim 500 coins by successfully verifying your X account",
      "bannerUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/twitter_verify.png",
      "iconUrl": "https://assets.avex.id/platforms/waitlist/socials/twitter.png",
      "additionalImageUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/twitter_verify.png",
      "taskType": "connection",
      "platform": "twitter",
      "tags": [
        "social"
      ],
      "reward": {
        "points": 500,
        "tickets": 0
      },
      "isActive": true,
      "boost": {
        "isBoosted": false,
        "multiplier": 1
      },
      "faq": [],
      "expiresAt": "2024-01-01T00:00:00.000Z",
      "notBeforeAt": "2024-05-10T17:27:01.937Z",
      "createdAt": "2024-05-10T17:27:01.937Z"
    },
    {
      "_id": "663e598c8f5488a4ec251031",
      "taskId": "zwqhgkuz1",
      "title": "Verify your Discord account",
      "description": "Claim 500 coins by successfully verifying your Discord account",
      "bannerUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/discord_verify.png",
      "iconUrl": "https://assets.avex.id/platforms/waitlist/socials/discord.png",
      "additionalImageUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/discord_verify.png",
      "taskType": "connection",
      "platform": "discord",
      "tags": [
        "social"
      ],
      "reward": {
        "points": 500,
        "tickets": 0
      },
      "isActive": true,
      "boost": {
        "isBoosted": false,
        "multiplier": 1
      },
      "faq": [],
      "expiresAt": "2024-01-01T00:00:00.000Z",
      "notBeforeAt": "2024-05-10T17:29:48.471Z",
      "createdAt": "2024-05-10T17:29:48.471Z"
    },
    {
      "_id": "663e5d388f5488a4ec251033",
      "taskId": "x97tcsg41",
      "title": "Verify your Telegram account",
      "description": "Claim 500 coins by successfully verifying your Telegram account",
      "bannerUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/telegram_verify.png",
      "iconUrl": "https://assets.avex.id/platforms/waitlist/socials/telegram.png",
      "additionalImageUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/telegram_verify.png",
      "taskType": "connection",
      "platform": "telegram",
      "tags": [
        "social"
      ],
      "reward": {
        "points": 500,
        "tickets": 0
      },
      "isActive": true,
      "boost": {
        "isBoosted": false,
        "multiplier": 1
      },
      "faq": [],
      "expiresAt": "2024-01-01T00:00:00.000Z",
      "notBeforeAt": "2024-05-10T17:45:28.634Z",
      "createdAt": "2024-05-10T17:45:28.634Z"
    },
    {
      "_id": "663e5da78f5488a4ec251034",
      "taskId": "4dqjrmpfz",
      "title": "Verify your Email",
      "description": "Claim 500 coins by successfully verifying your Email",
      "bannerUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/email.png",
      "iconUrl": "https://assets.avex.id/platforms/waitlist/socials/mail.png",
      "additionalImageUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/email.png",
      "taskType": "connection",
      "platform": "email",
      "tags": [
        "social"
      ],
      "reward": {
        "points": 500,
        "tickets": 0
      },
      "isActive": true,
      "boost": {
        "isBoosted": true,
        "multiplier": 2
      },
      "faq": [],
      "expiresAt": "2024-01-01T00:00:00.000Z",
      "notBeforeAt": "2024-05-10T17:47:19.784Z",
      "createdAt": "2024-05-10T17:47:19.784Z"
    },
    {
      "_id": "663e5e108f5488a4ec251035",
      "taskId": "wugp9flas",
      "title": "Follow Defy on X",
      "description": "Claim 500 coins by following Defy on X",
      "bannerUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/twitter_follow.png",
      "iconUrl": "https://assets.avex.id/platforms/waitlist/socials/twitter.png",
      "additionalImageUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/twitter_follow.png",
      "taskType": "twitterFollow",
      "tags": [
        "social"
      ],
      "reward": {
        "points": 500,
        "tickets": 0
      },
      "isActive": true,
      "boost": {
        "isBoosted": false,
        "multiplier": 1
      },
      "faq": [],
      "links": [
        "https://twitter.com/intent/follow?screen_name=defyapp_"
      ],
      "expiresAt": "2024-01-01T00:00:00.000Z",
      "notBeforeAt": "2024-05-10T17:49:04.701Z",
      "createdAt": "2024-05-10T17:49:04.701Z"
    },
    {
      "_id": "663e5f4c8f5488a4ec251036",
      "taskId": "r7g54pwsm",
      "title": "Like the campaign post",
      "description": "Claim 500 coins by helping us spread the word on Defy’s waitlist campaign",
      "bannerUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/twitter_like.png",
      "iconUrl": "https://assets.avex.id/platforms/waitlist/socials/twitter.png",
      "additionalImageUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/twitter_like.png",
      "taskType": "twitterTweetLike",
      "tags": [
        "social"
      ],
      "reward": {
        "points": 500,
        "tickets": 0
      },
      "isActive": true,
      "boost": {
        "isBoosted": false,
        "multiplier": 1
      },
      "faq": [],
      "verify": {
        "tweetId": [
          "1778074480666988780"
        ]
      },
      "links": [
        "https://twitter.com/intent/like?tweet_id=1778074480666988780"
      ],
      "expiresAt": "2024-01-01T00:00:00.000Z",
      "notBeforeAt": "2024-05-10T17:54:20.772Z",
      "createdAt": "2024-05-10T17:54:20.772Z"
    },
    {
      "_id": "663e5fa18f5488a4ec251037",
      "taskId": "a2041cbc5",
      "title": "Quote on Defy’s post",
      "description": "Claim 500 coins by sharing your thoughts on Defy’s campaign by writing a quote with tags #Defy #Aptos",
      "bannerUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/twitter_quote.png",
      "iconUrl": "https://assets.avex.id/platforms/waitlist/socials/twitter.png",
      "additionalImageUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/twitter_quote.png",
      "taskType": "twitterQuoteRetweet",
      "tags": [
        "social"
      ],
      "reward": {
        "points": 1000,
        "tickets": 0
      },
      "isActive": true,
      "boost": {
        "isBoosted": false,
        "multiplier": 1
      },
      "faq": [],
      "verify": {
        "tweetUrl": [
          "https://twitter.com/defyapp_/status/1778074480666988780"
        ]
      },
      "links": [
        "https://twitter.com/intent/retweet?tweet_id=1778074480666988780"
      ],
      "expiresAt": "2024-01-01T00:00:00.000Z",
      "notBeforeAt": "2024-05-10T17:55:45.998Z",
      "createdAt": "2024-05-10T17:55:45.998Z"
    },
    {
      "_id": "663e5fef8f5488a4ec251038",
      "taskId": "57oegmu95",
      "title": "Retweet the campaign post",
      "description": "Claim 500 coins by helping us spread the word on Defy’s waitlist campaign",
      "bannerUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/twitter_retweet.png",
      "iconUrl": "https://assets.avex.id/platforms/waitlist/socials/twitter.png",
      "additionalImageUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/twitter_retweet.png",
      "taskType": "twitterRetweet",
      "tags": [
        "social"
      ],
      "reward": {
        "points": 1000,
        "tickets": 0
      },
      "isActive": true,
      "boost": {
        "isBoosted": false,
        "multiplier": 1
      },
      "faq": [],
      "verify": {
        "tweetId": [
          "1778074480666988780"
        ]
      },
      "links": [
        "https://twitter.com/intent/retweet?tweet_id=1778074480666988780"
      ],
      "expiresAt": "2024-01-01T00:00:00.000Z",
      "notBeforeAt": "2024-05-10T17:57:03.263Z",
      "createdAt": "2024-05-10T17:57:03.263Z"
    },
    {
      "_id": "663e62078f5488a4ec25103a",
      "taskId": "7qi90tton",
      "title": "Join our Telegram channel",
      "description": "Claim 500 coins by joining the Defy family and getting access to early alpha",
      "bannerUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/telegram.png",
      "iconUrl": "https://assets.avex.id/platforms/waitlist/socials/telegram.png",
      "additionalImageUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/telegram.png",
      "taskType": "telegramChannelJoin",
      "tags": [
        "social"
      ],
      "reward": {
        "points": 500,
        "tickets": 0
      },
      "isActive": true,
      "boost": {
        "isBoosted": false,
        "multiplier": 1
      },
      "faq": [],
      "verify": {
        "channelId": [
          "-1001636895070_602"
        ]
      },
      "links": [
        "https://t.me/avexdefy/602"
      ],
      "expiresAt": "2024-01-01T00:00:00.000Z",
      "notBeforeAt": "2024-05-10T18:05:59.748Z",
      "createdAt": "2024-05-10T18:05:59.748Z"
    },
    {
      "_id": "663e625a8f5488a4ec25103b",
      "taskId": "22ig3bjv1",
      "title": "QnA",
      "description": "Claim 100 coins by reading and answering some Qs",
      "bannerUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/qna_about_blogs.png",
      "iconUrl": "https://assets.avex.id/platforms/waitlist/socials/faq.png",
      "additionalImageUrl": "https://assets.avex.id/platforms/waitlist/socials/banners/qna_about_blogs.png",
      "taskType": "mcq",
      "tags": [
        "social"
      ],
      "reward": {
        "points": 1000,
        "tickets": 0
      },
      "isActive": true,
      "boost": {
        "isBoosted": true,
        "multiplier": 2
      },
      "faq": [],
      "mcqQuestions": [
        {
          "question": "What does the term “Defy” signify?",
          "answers": [
            "interchange “Defi” with “Defy”",
            "openly resist or refuse to obey",
            "decentralized finance",
            "decentralized exchange"
          ],
          "correctAnswer": 1,
          "referenceMaterialUrl": "https://medium.com/@maharsi/defyapp-d7ffe488968c"
        },
        {
          "question": "What is our aim?",
          "answers": [
            "Make decentralized finance accessible to 5 billions users”",
            "To support centralized exchanges",
            "Make bitcoin usable by all",
            "Make mining process easy"
          ],
          "correctAnswer": 0,
          "referenceMaterialUrl": "https://medium.com/@maharsi/defyapp-d7ffe488968c"
        },
        {
          "question": "Who do we “defy”?",
          "answers": [
            "Michael Saylor",
            "Gay Jensler",
            "Ansem",
            "Naresh"
          ],
          "correctAnswer": 1,
          "referenceMaterialUrl": "https://medium.com/@maharsi/defyapp-d7ffe488968c"
        },
        {
          "question": "When was Bitcoin introduced?",
          "answers": [
            "Amidst the Global Financial Crisis",
            "When Satoshi was made public",
            "After launching Ethereum",
            "When Powell increased interest rates"
          ],
          "correctAnswer": 0,
          "referenceMaterialUrl": "https://medium.com/@maharsi/defyapp-d7ffe488968c"
        },
        {
          "question": "How are centralized marketplaces ruining the ecosystem?",
          "answers": [
            "Bizarre marketing campaigns",
            "Illicit financial activities",
            "By going against the initial vision of blockchain",
            "All of the above"
          ],
          "correctAnswer": 3,
          "referenceMaterialUrl": "https://medium.com/@maharsi/defyapp-d7ffe488968c"
        }
      ],
      "expiresAt": "2024-01-01T00:00:00.000Z",
      "notBeforeAt": "2024-05-10T18:07:22.514Z",
      "createdAt": "2024-05-10T18:07:22.514Z"
    }
  ],
  defaultDailyRewards: [{
    "_id": {
      "$oid": "663e666e8f5488a4ec251045"
    },
    "rewardId": "rffsmmal0",
    "title": "Day 1",
    "description": "Day 1 of the 7 day reward!",
    "sequence": 1,
    "bannerUrl": "https://picsum.photos/200/300",
    "iconUrl": "https://picsum.photos/50",
    "additionalImageUrl": "https://picsum.photos/50",
    "reward": {
      "points": 150,
      "tickets": 0
    },
    "createdAt": {
      "$date": "2024-05-10T18:24:46.452Z"
    }
  },
  {
    "_id": {
      "$oid": "663e667c8f5488a4ec251046"
    },
    "rewardId": "frhz5rd1x",
    "title": "Day 2",
    "description": "Day 2 of the 7 day reward!",
    "sequence": 2,
    "bannerUrl": "https://picsum.photos/200/300",
    "iconUrl": "https://picsum.photos/50",
    "additionalImageUrl": "https://picsum.photos/50",
    "reward": {
      "points": 200,
      "tickets": 0
    },
    "createdAt": {
      "$date": "2024-05-10T18:25:00.004Z"
    }
  },
  {
    "_id": {
      "$oid": "663e668b8f5488a4ec251047"
    },
    "rewardId": "bh1njd68e",
    "title": "Day 3",
    "description": "Day 3 of the 7 day reward!",
    "sequence": 3,
    "bannerUrl": "https://picsum.photos/200/300",
    "iconUrl": "https://picsum.photos/50",
    "additionalImageUrl": "https://picsum.photos/50",
    "reward": {
      "points": 0,
      "tickets": 1
    },
    "createdAt": {
      "$date": "2024-05-10T18:25:15.422Z"
    }
  },
  {
    "_id": {
      "$oid": "663e66998f5488a4ec251048"
    },
    "rewardId": "s05nmr7jy",
    "title": "Day 4",
    "description": "Day 4 of the 7 day reward!",
    "sequence": 4,
    "bannerUrl": "https://picsum.photos/200/300",
    "iconUrl": "https://picsum.photos/50",
    "additionalImageUrl": "https://picsum.photos/50",
    "reward": {
      "points": 350,
      "tickets": 0
    },
    "createdAt": {
      "$date": "2024-05-10T18:25:29.909Z"
    }
  },
  {
    "_id": {
      "$oid": "663e66a48f5488a4ec251049"
    },
    "rewardId": "2lzjkfkcs",
    "title": "Day 5",
    "description": "Day 5 of the 7 day reward!",
    "sequence": 5,
    "bannerUrl": "https://picsum.photos/200/300",
    "iconUrl": "https://picsum.photos/50",
    "additionalImageUrl": "https://picsum.photos/50",
    "reward": {
      "points": 400,
      "tickets": 0
    },
    "createdAt": {
      "$date": "2024-05-10T18:25:40.399Z"
    }
  },
  {
    "_id": {
      "$oid": "663e66b08f5488a4ec25104a"
    },
    "rewardId": "yanqkitz2",
    "title": "Day 6",
    "description": "Day 6 of the 7 day reward!",
    "sequence": 6,
    "bannerUrl": "https://picsum.photos/200/300",
    "iconUrl": "https://picsum.photos/50",
    "additionalImageUrl": "https://picsum.photos/50",
    "reward": {
      "points": 0,
      "tickets": 2
    },
    "createdAt": {
      "$date": "2024-05-10T18:25:52.991Z"
    }
  },
  {
    "_id": {
      "$oid": "663e675a8f5488a4ec25104b"
    },
    "rewardId": "l3w8gcdby",
    "title": "Day 7",
    "description": "Day 7 of the 7 day reward!",
    "sequence": 7,
    "bannerUrl": "https://picsum.photos/200/300",
    "iconUrl": "https://picsum.photos/50",
    "additionalImageUrl": "https://picsum.photos/50",
    "reward": {
      "points": 0,
      "tickets": 0,
      "fungibleAssets": [
        {
          "name": "GUI",
          "value": 10000,
          "imageUrl": "https://picsum.photos/200/300"
        }
      ]
    },
    "createdAt": {
      "$date": "2024-05-10T18:28:42.863Z"
    }
  }]
};

export default CONST;
